<template>
  <div>
    <div :id="id" :style="{ width: width, height: height, margin: 'auto' }"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'
import { TooltipComponent, LegendComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])

export default {
  props: {
    id: {
      type: String,
      default: () => {
        return 'main'
      }
    },
    dataList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    width: {
      type: String,
      default: () => {
        return '100%'
      }
    },
    height: {
      type: String,
      default: () => {
        return '4.5rem'
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fnInit()
    })
  },
  methods: {
    fnInit() {
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option
      const seriesArray = this.dataList.seriesArray
      option = {
        color: ['#3BA1FF', '#4FCB74', '#FBD438', '#F04764', '#9860E5', '#9a60b4'],
        series: seriesArray
      }

      option && myChart.setOption(option)
    }
  },
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler: function (n, o) {
        console.log(n, o)
        if (Object.keys(n).length !== 0) {
          this.fnInit()
        }
      }
    }
  }
}
</script>