import request from '@/utils/request'

const api = {    
    dataInit: '/api/data/init', // 首页初始化
    dataOverView: '/api/data/overview', // 首页初始化
    dataGameoverView: '/api/data/gameoverview' // 首页游戏概览
}

export function dataGameoverView(parameter) {
    return request({
        url: api.dataGameoverView,
        method: 'get',
        params: parameter
    })
}

export function dataOverView(parameter) {
    return request({
        url: api.dataOverView,
        method: 'get',
        params: parameter
    })
}

export function dataInit(parameter) {
    return request({
        url: api.dataInit,
        method: 'get',
        params: parameter
    })
}

