<template>
  <div class="footer">
    <ul>
      <li class="list1" :class="isshow1 && 'on'">
        <a href="/" title="">
          <i></i>
          <p>产品</p>
        </a>
      </li>
      <li class="list4" :class="isshow2 && 'on'">
        <a href="/topreels" title="">
          <i></i>
          <p>TopReels</p>
        </a>
      </li>
      <li class="list2">
        <a href="/oldpage/version_number.html" title="">
          <i></i>
          <p>版号</p>
        </a>
      </li>
      <li class="list3">
        <a href="/oldpage/my.html" title="">
          <i></i>
          <p>我的</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isshow1: false,
      isshow2: false
    }
  },
  mounted() {
    console.log(this.$route.path)
    if (this.$route.path === '/') {
      this.isshow1 = true
    }
    if (this.$route.path === '/topreels') {
      this.isshow2 = true
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  height: (98 / @num);
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px (-8 / @num) (48 / @num) 0px rgba(0, 0, 0, 0.08);
  padding: 0 (88 / @num);
  z-index: 100;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    li {
      a {
        font-size: (20 / @num);
        color: #d7dce0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        i {
          display: block;
          width: (48 / @num);
          height: (48 / @num);
        }
        p {
          color: #d7dce0;
        }
      }
      &.list1 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon_f1.png') no-repeat;
          background-size: 100%;
        }
      }
      &.list2 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon_f2.png') no-repeat;
          background-size: 100%;
        }
      }
      &.list3 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon_f3.png') no-repeat;
          background-size: 100%;
        }
      }
      &.list4 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon4.png') no-repeat;
          background-size: 100%;
        }
      }
      &.on {
        a {
          color: #018cff;
          p {
            color: #018cff;
          }
        }
      }
      &.on.list1 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon_f1_hover.png') no-repeat;
          background-size: 100%;
        }
      }
      &.on.list2 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon_f2_hover.png') no-repeat;
          background-size: 100%;
        }
      }
      &.on.list3 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon_f3_hover.png') no-repeat;
          background-size: 100%;
        }
      }
      &.on.list4 {
        i {
          background: url('//image.ledu.com/miaoju/zs/images/icon4-hover.png') no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
</style>
