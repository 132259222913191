<template>
  <div class="head-tabs">
    <ul :style="{ 'justify-content': justify }">
      <li
        v-for="(item, index) in tabsList"
        :key="index"
        :class="[active === index ? 'active' : '']"
        @click="
          fnClick({
            index: index,
            item: item
          })
        "
        :style="{ width: 100 / tabsList.length + '%' }"
      >
        <span :style="{ 'font-size': fontSize }">
          {{ item.name }}
          <em :style="{ width: lineWidth }"></em>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array,
      default: () => {
        return []
      }
    },
    fnClickTabs: {
      type: Function,
      default: () => {}
    },
    justify: {
      type: String,
      default: 'flex-start'
    },
    fontSize: {
      type: String,
      default: '0.28rem'
    },
    lineWidth: {
      type: String,
      default: '50%'
    },
    currentIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabsList: [],
      active: 0
    }
  },
  methods: {
    fnClick(option) {
      this.active = option.index
      this.fnClickTabs(option)
    }
  },
  watch: {
    tabs: {
      immediate: true,
      handler(n) {
        this.tabsList = [].concat(n)
      }
    },
    currentIndex: {
      immediate: true,
      handler(n) {
        this.active = n
      }
    }
  }
}
</script>

<style lang="less" scoped>
.head-tabs {
  width: 100%;
  height: 100%;
  ul {
    display: flex;
    align-items: center;
    height: 100%;
    li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      span {
        color: @font-686868;
      }
      &.active {
        span {
          position: relative;
          color: @paramcolor;
          font-size: @font-32 !important;
          em {
            position: absolute;
            bottom: -(15 / @num);
            left: 0;
            right: 0;
            margin: auto;
            width: 50%;
            height: (8 / @num);
            background: @paramcolor;
            border-radius: (4 / @num);
          }
        }
      }
    }
  }
}
</style>