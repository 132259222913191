<template>
  <div :id="id" :style="{ width: width, height: height, margin: 'auto' }"></div>
</template>
<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, DataZoomComponent } from 'echarts/components'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  DataZoomComponent
])
export default {
  name: 'EchartColumn',
  props: {
    id: {
      type: String,
      default: () => {
        return 'main'
      }
    },
    dataList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    width: {
      type: String,
      default: () => {
        return '100%'
      }
    },
    height: {
      type: String,
      default: () => {
        return '4.5rem'
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    fnInit() {
      // 接下来的使用就跟之前一样，初始化图表，设置配置项
      var myChart = echarts.init(document.getElementById(this.id))
      // myChart.clear()
      const legendArray = this.dataList.legendArray
      const xAxisArray = this.dataList.xAxisArray
      const yAxisArray = this.dataList.yAxisArray
      const seriesArray = this.dataList.seriesArray
      const tooltipArray = this.dataList.tooltipArray
      const dataZoomArray = this.dataList.dataZoomArray
      const gridArray = this.dataList.gridArray
      console.log('xAxis', xAxisArray)
      console.log('yAxis', yAxisArray)
      console.log('legendArray', legendArray)
      console.log('seriesArray', seriesArray)
      console.log('tooltipArray', tooltipArray)
      console.log('dataZoomArray', dataZoomArray)
      const option = {
        color: ['#5393f7', '#fac858', '#1bcdd4', '#91cc75', '#73c0de', '#9a60b4'],
        tooltip: tooltipArray,
        legend: legendArray,
        grid: gridArray,
        dataZoom: dataZoomArray,
        xAxis: xAxisArray,
        yAxis: yAxisArray,
        series: seriesArray
      }
      myChart.setOption(option, true)
    }
  },
  watch: {
    dataList: {
      immediate: true,
      deep: true,
      handler: function (n, o) {
        console.log(n, o)
        if (Object.keys(n).length !== 0) {
          this.fnInit()
        }
      }
    }
  }
}
</script>